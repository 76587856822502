import {useContext} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
// import {useLinkTo} from '@react-navigation/native';
import {DataTable, Headline} from 'react-native-paper';

import {
  displayNameFromUser,
  // useConnectedUsers,
  // useUsersByCitizenNumber,
} from '../hooks/useUserInfo';
// import {BillingTransaction, User} from '../models/IdentityModels';
import {ScoringInfoContext} from './Stack';
import {format} from '../translations';
import {useAppSettings} from '../components/AppSettings';
import {useBillingTransactions} from '../hooks/useBillingInfo';
import XDate from 'xdate';

export const BillingReportScreen = () => {
  const appSettings = useAppSettings();
  // const linkTo = useLinkTo();
  const scoringInfo = useContext(ScoringInfoContext);
  const scoringAsUser = scoringInfo?.scoringUserInfo?.scoringAsUser;
  const billingTransactions = useBillingTransactions(scoringAsUser?.kullkiId);
  const scoringAsUserDisplayName = displayNameFromUser(scoringAsUser);
  // const connectedUsers = useConnectedUsers(scoringInfo?.scoringUserInfo?.scoringAsUser?.kullkiId);
  // console.warn('UserList connectedUsers: ' + JSON.stringify(connectedUsers));
  // const [searchQuery, setSearchQuery] = useState('');
  // const [searchUsersQueryString, setSearchUsersQueryString] = useState<string | undefined>(
  //   undefined,
  // );
  // let searchUsers = useUsersByCitizenNumber(searchUsersQueryString, scoringAsUser?.kullkiId);
  // const onChangeSearch = (query: string) => {
  //   setSearchQuery(query);
  //   if (query === '' && searchUsers !== undefined) {
  //     searchUsers = undefined;
  //     setSearchUsersQueryString(undefined);
  //   }
  // };

  // const handleSearch = () => {
  //   if (searchQuery !== '') {
  //     console.log('UserList should search for ' + searchQuery);
  //     setSearchUsersQueryString(searchQuery);
  //   }
  // };

  // let billingTransactions: BillingTransaction[] | undefined = billingTransactions;

  // if (searchUsers !== undefined) {
  //   userList = searchUsers;
  // }

  // const handleUserInformation = (user: User) => {
  //   scoringInfo?.setScoringUserInfo({
  //     ...scoringInfo.scoringUserInfo,
  //     scoringOnUser: user,
  //   });
  //   linkTo('/scoring/user/detail');
  // };

  return (
    <ScrollView contentContainerStyle={styles.pageContainer}>
      <Headline style={styles.appBarButtonPadding}>
        {format(appSettings.t('BillingTransactionCount'), [
          (billingTransactions?.length ?? 0) + '',
          scoringAsUserDisplayName,
        ])}
      </Headline>

      <View style={styles.centeredPadded} />
      {/* <Searchbar
        onIconPress={handleSearch}
        placeholder={format(appSettings.t('ConsultScoresSearchPlaceholder'), [
          appSettings.t('ProfileCedulaLabel'),
        ])}
        onChangeText={onChangeSearch}
        value={searchQuery}
      /> */}
      <View style={styles.centeredPadded} />

      <DataTable>
        <DataTable.Header>
          <DataTable.Title sortDirection="descending">
            {appSettings.t('BillingTransactionDate')}
          </DataTable.Title>
          <DataTable.Title>{appSettings.t('BillingTransactionType')}</DataTable.Title>
          <DataTable.Title>{appSettings.t('BillingTransactionQuantity')}</DataTable.Title>
          <DataTable.Title>{appSettings.t('BillingTransactionBalance')}</DataTable.Title>
        </DataTable.Header>

        {/* {!!searchUsersQueryString && userList?.length === 0 && (
          <Text style={styles.centeredPadded}>{appSettings.t('ConsultScoresSearchNoResults')}</Text>
        )} */}

        {billingTransactions
          ?.sort((a, b) => a.date - b.date)
          .map(txn => {
            return (
              <DataTable.Row key={txn.id}>
                <DataTable.Cell>
                  {new XDate(txn.date).toString(appSettings.t('LatestDeviceEventDateFormat'))}
                </DataTable.Cell>
                <DataTable.Cell>
                  {appSettings.t(`BillingTransactionType${txn.type}`)}
                </DataTable.Cell>
                <DataTable.Cell>{txn.quantity}</DataTable.Cell>
                <DataTable.Cell>{txn.currentBalance}</DataTable.Cell>
                {/* <DataTable.Cell>
                <Button
                  mode="contained"
                  // @ts-ignore FIXME type the navigator...
                  onPress={() => handleUserInformation(connectedUser)}>
                  {appSettings.t('ConsultScoresViewDetails')}
                </Button>
              </DataTable.Cell> */}
              </DataTable.Row>
            );
          })}
      </DataTable>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  appBarButtonPadding: {
    padding: 10,
  },
  centeredPadded: {
    alignSelf: 'center',
    alignContent: 'center',
    padding: 15,
  },
  pageContainer: {
    flex: 1,
    padding: 20,
  },
});
